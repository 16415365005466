import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=1189b52a"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=1189b52a&prod&lang=postcss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlyoutMenu: require('/usr/src/app/components/FlyoutMenu.vue').default,Logo: require('/usr/src/app/components/Logo.vue').default,LocaleSelector: require('/usr/src/app/components/LocaleSelector.vue').default,UserDropdown: require('/usr/src/app/components/UserDropdown.vue').default})
