
import { mapGetters } from "vuex";
import BagIcon from "@/components/BagIcon";
import CloseIcon from "@/assets/svg/heroicons/x-circle.svg?inline";
import ImageIcon from "@/assets/svg/heroicons/image.svg?inline";
import SignInModal from "~/modals/SignInModal.vue";
import SignUpModal from "~/modals/SignUpModal.vue";

export default {
  components: {
    BagIcon,
    CloseIcon,
    ImageIcon,
  },
  data() {
    return {
      isBag: this.$route.path.includes("bag"),
      scrollPosition: 0,
      isStorePage: false,
      isProductPage: false,
    };
  },
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
    name: {
      type: String,
      default: "header",
    },
  },
  computed: {
    ...mapGetters({
      cartItems: "cart/getItems",
      cartTotalItems: "cart/getNumberOfItems",
      cartUUID: "cart/getUUID",
    }),
  },
  watch: {
    $route() {
      if (window.location.href.includes("/products/")) {
        this.isProductPage = true;
      } else {
        this.isProductPage = false;
      }
      if (window.location.href.includes("/stores")) {
        this.isStorePage = true;
      } else {
        this.isStorePage = false;
      }
    },
  },
  mounted() {
    this.isProductPage = window.location.href.includes("/products/");
    this.isStorePage = window.location.href.includes("/stores");

    if (!this.$store.state.cart.init) {
      this.$store.dispatch("cart/fetchCartItems");
    }
    if (process.browser) {
      window.addEventListener("scroll", this.updateScroll);
    }
  },
  beforeDestroy() {
    if (process.browser) {
      window.removeEventListener("scroll", this.updateScroll);
    }
  },
  methods: {
    removeCartItem(uuid) {
      this.$store.dispatch("cart/removeCartItem", uuid);
    },
    prepareForCheckOut() {
      this.$router.push(this.$i18nPath(`/checkout/${this.cartUUID}`));
    },
    async logoutNow(onClickOpen) {
      onClickOpen();
      if (this.$intercom) {
        this.$intercom.shutdown();
        this.$intercom.update({});
      }
      await this.$store.dispatch("auth/logout");

      await this.$router.push(this.$i18nPath("/"));
    },
    openSignInModal(onClickOpen) {
      onClickOpen();
      this.$modal.show(SignInModal, {
        clickToClose: true,
      });
    },
    openSignUpModal(onClickOpen) {
      onClickOpen();
      this.$modal.show(SignUpModal, {
        clickToClose: true,
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
