import { render, staticRenderFns } from "./UserDropdown.vue?vue&type=template&id=274510b5&scoped=true"
import script from "./UserDropdown.vue?vue&type=script&lang=js"
export * from "./UserDropdown.vue?vue&type=script&lang=js"
import style0 from "./UserDropdown.vue?vue&type=style&index=0&id=274510b5&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274510b5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BagIcon: require('/usr/src/app/components/BagIcon.vue').default,Btn: require('/usr/src/app/components/Btn.vue').default,Dropdown: require('/usr/src/app/components/Dropdown.vue').default})
