
import { mapGetters } from "vuex";
import CloseIcon from "@/assets/svg/heroicons/x-circle.svg?inline";
import MobileMenuIcon from "@/components/MobileMenuIcon.vue";
export default {
  components: {
    MobileMenuIcon,
    CloseIcon,
  },
  data() {
    return {
      visible: false,
      isProductPage: false,
      isStorePage: false,
      drawer: null,
      scrollPosition: 0,
    };
  },
  computed: {
    ...mapGetters({
      products: "getProducts",
      categories: "getCategories",
      currentSite: "getCurrentSite",
    }),
  },
  watch: {
    $route() {
      if (window.location.href.includes("/products/")) {
        this.isProductPage = true;
      } else {
        this.isProductPage = false;
      }
      if (window.location.href.includes("/stores")) {
        this.isStorePage = true;
      } else {
        this.isStorePage = false;
      }
    },
  },

  mounted() {
    this.isProductPage = window.location.href.includes("/products/");
    this.isStorePage = window.location.href.includes("/stores");
    const MmenuLight = require("mmenu-light/esm/core/index").default;

    const menu = new MmenuLight(
      document.querySelector("#mmenu"),
      "(max-width: 1024px)"
    );

    /* eslint-disable-next-line */
    const navigator = menu.navigation();
    this.drawer = menu.offcanvas({
      position: this.$store.state.isRtl ? "right" : "left",
    });
    if (process.browser) {
      window.addEventListener("scroll", this.updateScroll);
    }

    this.visible = true;
  },
  beforeDestroy() {
    if (process.browser) {
      window.removeEventListener("scroll", this.updateScroll);
    }
  },
  methods: {
    onClickLink(route) {
      this.drawer.close();
      this.$router.push(route);
    },
    open() {
      this.drawer.open();
    },
    close() {
      this.drawer.close();
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
