
import { mapGetters } from "vuex";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
import "vue-collapsible-component/lib/vue-collapsible.css";
import Collapsible from "vue-collapsible-component";
import PlusIcon from "@/assets/svg/heroicons/plus.svg?inline";
import CloseIcon from "@/assets/svg/heroicons/x.svg?inline";
import PhoneIcon from "~/assets/svg/heroicons/phone.svg?inline";
import FacebookIcon from "~/assets/svg/heroicons/facebook.svg?inline";
import TwitterIcon from "~/assets/svg/heroicons/twitter.svg?inline";
import InstagramIcon from "~/assets/svg/heroicons/instagram.svg?inline";
import YouTubeIcon from "~/assets/svg/heroicons/youtube.svg?inline";
import LinkedInIcon from "~/assets/svg/heroicons/linkedin.svg?inline";
import TikTokIcon from "~/assets/svg/heroicons/tiktok.svg?inline";
// import Graybg from '~/assets/images/general/Graybg.svg?inline'

export default {
  components: {
    Collapsible,
    PlusIcon,
    CloseIcon,
    PhoneIcon,
    FacebookIcon,
    TwitterIcon,
    InstagramIcon,
    YouTubeIcon,
    LinkedInIcon,
    TikTokIcon,
    // Graybg
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  data(d) {
    return {
      browserWidth: 0,
      group_companies: [],
      year: new Date().getFullYear(),
      intercom: typeof d.$intercom !== "undefined" ? d.$intercom : null,
    };
  },
  computed: {
    ...mapGetters({
      products: "getProducts",
      currentSiteSettings: "getCurrentSiteSettings",
      currentSite: "getCurrentSite",
      features: "getFeatures",
    }),
    textClass() {
      return this.$store.state.locale === "el"
        ? "text-[17px] 2xl:text-[20px] font-bold"
        : "text-[30px]";
    },
  },
  mounted() {
    if (this.features) {
      const findData = this.features.find((_v) => _v.slug == "group-companies");
      if (findData && findData.settings) {
        this.group_companies = findData.settings;
      }
    }
    this.browserWidth = this.$vssWidth;
    if (process.browser) {
      window.addEventListener("resize", this.windowResized);
    }
  },
  beforeDestroy() {
    if (process.browser) {
      window.removeEventListener("resize", this.windowResized);
    }
  },
  methods: {
    windowResized() {
      this.browserWidth = this.$vssWidth;
    },
  },
};
