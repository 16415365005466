
import ResetAccountPasswordModal from "~/modals/ResetAccountPasswordModal.vue";
import SignInModal from "~/modals/SignInModal.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    isStorePage() {
      return this.$route.path.startsWith(this.$i18nPath("/stores"));
    },
    ...mapGetters({
      currentSite: "getCurrentSite",
    }),
  },
  watch: {
    $route() {
      if (this.$route.query && this.$route.query.login) {
        this.$router.replace({ query: null });
        this.$modal.show(SignInModal);
      }
    },
  },
  mounted() {
    this.$loadIubendaCookieConsent();
    this.$loadGTM();

    // this.$store.dispatch("loadCountry");

    if (!this.isStorePage && this.$loadIntercom) {
      this.$loadIntercom();
    }
    if (this.$route.query && this.$route.query.login) {
      this.$router.replace({ query: null });
      this.$modal.show(SignInModal);
    }
    if (this.$route.query && this.$route.query.token) {
      this.$axios
        .$get(
          `api/auth/password/find/${
            this.$route.query && this.$route.query.token
          }`
        )
        .then((res) => {
          if (res.data) {
            this.$modal.show(ResetAccountPasswordModal, {
              token: res.data.token,
              email: res.data.email,
            });
          }
        });
    }
  },
  head() {
    const baseAppUrl = this.$config.APP_URL;
    const styles = [];
    // eslint-disable-next-line
    let metaLinks = [
      { rel: "alternate", href: `${baseAppUrl}`, hreflang: "x-default" },
      {
        rel: "canonical",
        href: `${baseAppUrl}${this.$route.path.substring(1)}`,
      },
    ];

    // Add alternate urls
    this.$store.state.sites
      .filter((site) => site.slug === this.$store.state.country)
      .forEach((site) => {
        site.urls.forEach((item) => {
          metaLinks.push({
            rel: "alternate",
            href: item.url,
            hreflang: item.locale_code,
          });
        });
      });

    return {
      titleTemplate: "%s | Keeway",
      link: metaLinks,
      style: styles,
      htmlAttrs: {
        lang: this.$store.state.locale,
        dir: this.$store.state.isRtl ? "rtl" : "ltr",
      },
    };
  },
};
