import { render, staticRenderFns } from "./default.vue?vue&type=template&id=254c94e0"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=254c94e0&prod&lang=postcss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/usr/src/app/components/Navigation.vue').default,MobileNavigation: require('/usr/src/app/components/MobileNavigation.vue').default,Newsletter: require('/usr/src/app/components/Newsletter.vue').default,Footer: require('/usr/src/app/components/Footer.vue').default})
